import React from "react"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

import Card from "./card"
import EventTitle from "./eventTitle"
import SpeakerName from "./speakerName"

import style from "./speakerCard.module.css"

const SpeakerCard = ({
	speakerSlug,
	size,
	linkImage,
	showName,
	linkName,
	showBio,
	showSocial,
	showTalks,
	showTrainings,
	showInteractives,
	invertColors,
	fillVertical,
	className,
}) => {
	const { team, speakers } = useStaticQuery(
		graphql`
			query {
				team: allMarkdownRemark(filter: { fields: { collection: { eq: "team" } } }) {
					edges {
						node {
							frontmatter {
								name
								bio
								slug
								...Social
								image
							}
						}
					}
				}
				speakers: allMarkdownRemark(
					filter: { fields: { collection: { eq: "speakers" } } }
				) {
					edges {
						node {
							fields {
								path
							}
							frontmatter {
								name
								bio
								slug
								...Social
								image
								talks {
									fields {
										path
									}
									frontmatter {
										slug
										language
										title
									}
								}
								trainings {
									fields {
										path
									}
									frontmatter {
										slug
										title
									}
								}
							}
						}
					}
				}
			}
		`
	)

	const speaker = speakers.edges
		.map(({ node }) => node)
		.filter(node => node.frontmatter.slug === speakerSlug)[0]

	const member = team.edges
		.map(({ node }) => node)
		.filter(node => node.frontmatter.slug === speakerSlug)[0]

	const person = speaker ? speaker : member

	if (person) {
		const imageSlug = person.frontmatter.image && person.frontmatter.slug
		const imageLink = linkImage ? person.fields.path : undefined
		const interactives = []
		if (person.frontmatter.panels) interactives.push(...person.frontmatter.panels)
		if (person.frontmatter.qa) interactives.push(...person.frontmatter.qa)
		if (person.frontmatter.lightningTalks) interactives.push(...person.frontmatter.lightningTalks)

		return (
			<Card
				size={size}
				imageSlug={imageSlug}
				imageLink={imageLink}
				invertColors={invertColors}
				fillVertical={fillVertical}
				className={className}
			>
				{showName && name(person, linkName, showSocial)}
				{showBio && bio(person)}
				{showTalks && eventList(person.frontmatter.talks)}
				{showTrainings && eventList(person.frontmatter.trainings)}
				{showInteractives && eventList(interactives)}
			</Card>
		)
	} else
		return (
			<Card
				size={size}
				imageSlug="unknown"
				invertColors={invertColors}
				className={className}
			/>
		)
}

function name(speaker, linkName, showSocial) {
	return (
		<h3 className={style.name}>
			<SpeakerName speaker={speaker} linkName={linkName} showSocial={showSocial} />
		</h3>
	)
}

function bio(speaker) {
	return <p className={style.bio} dangerouslySetInnerHTML={{ __html: speaker.frontmatter.bio }} />
}

function eventList(events) {
	if (!events) return null
	return (
		<p className={style.event}>
			{events.map(event => (
				<EventTitle key={event.frontmatter.slug} event={event} linkTitle />
			))}
		</p>
	)
}

export default SpeakerCard
