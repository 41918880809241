import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import style from "./image.module.css"

const Image = ({ slug, square, size, position, invertColors }) => {
	// by default, images are square
	square = square === undefined || square === null || square === true
	const classes = []
	if (square) classes.push(style.squareImage)
	if (size) classes.push(style[size])
	if (position) classes.push(style[position])
	if (invertColors) classes.push(style.invert)
	const image = useImage(slug)
	if (image) return <Img className={classes.join(" ")} fluid={image.fluid} />
	else {
		console.warn("Missing image: " + slug)
		return null
	}
}

function useImage(slug) {
	const { images } = useStaticQuery(
		graphql`
			query {
				images: allImageSharp(
					filter: {
						fields: {
							collection: {
								in: ["content-images", "partner-images", "speaker-images"]
							}
						}
					}
				) {
					edges {
						node {
							parent {
								... on File {
									id
									name
									relativePath
								}
							}
							fluid(maxWidth: 1000, srcSetBreakpoints: [300, 1000, 2000], jpegQuality: 80) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		`
	)
	return images.edges.map(({ node }) => node).filter(node => node.parent.name === slug)[0]
}

export default Image
