import React from "react"

import Link from "./link.js"
import Powered from "./powered.js"
import Snippet from "./snippet.js"
import Card from "./card.js"
import SpeakerCard from "./speakerCard.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import style from "./footer.module.css"

export default () => (
	<footer>
		<div id={style.topLine} />
		<div className={style.content}>
			<div id={style.team}>
				<h2>The Team</h2>
				<p>We can't wait to meet you!</p>
				<div className={style.cards}>
					<SpeakerCard
						speakerSlug="uta-leonhardt"
						size="small"
						showName
						showBio
						invertColors
						className={style.member}
					/>
					<SpeakerCard
						speakerSlug="nicolai-parlog-team"
						size="small"
						showName
						showBio
						invertColors
						className={style.member}
					/>
				</div>
			</div>
			<div id={style.partners}>
				<h2>Partners</h2>
				<p>Let's introduce our friendly supporters.</p>
				<div className={style.cards}>
					<Card size="small" className={style.partner} imageSlug="cjt" imageLink="https://www.cjt.de/" invertColors />
					<Card size="small" className={style.partner} imageSlug="lets-dev" imageLink="https://letsdev.de/" invertColors />
				</div>
				{/* NOTE: reuse for new partners */}
				{/*
				<h3 style={{textAlign: "center"}}>Media Partners</h3>
				<div className={style.cards}>
					<Card size="small" className={style.partner} imageSlug="cyberforum" imageLink="https://www.cyberforum.de/" invertColors />
					<Card size="small" className={style.partner} imageSlug="dpunkt" imageLink="https://www.dpunkt.de/" invertColors />
					<Card size="small" className={style.partner} imageSlug="mein-ka" imageLink="https://meinka.de/" invertColors />
					<Card size="small" className={style.partner} imageSlug="stickermule" imageLink="http://stickermule.com/supports/accento19-sponsorship" invertColors />
					<Card size="small" className={style.partner} imageSlug="manning" imageLink="https://www.manning.com/" invertColors />
				</div>
				 */}
			</div>
			<div id={style.coc}>
				<Snippet id="coc-short" />
			</div>
			<div id={style.contact}>
				<h2>Contact</h2>
				<ul>
					<li>
						<FontAwesomeIcon icon={["fas", "ticket-alt"]} />{" "}
						<a href="https://www.eventbrite.de/e/accento-conference-2021-javajavascriptops-tickets-144982846597">tickets</a>
					</li>
					<li>
						<FontAwesomeIcon icon={["fas", "envelope"]} />{" "}
						<Link to="mailto:hello@accento.dev?subject=Hi!">
							hello@accento.dev
						</Link>
					</li>
					<li>
						<FontAwesomeIcon icon={["fab", "twitter"]} />{" "}
						<Link to="https://twitter.com/AccentoDev">@AccentoDev</Link>
					</li>
					<li>
						<FontAwesomeIcon icon={["fab", "youtube"]} />{" "}
						<Link to="https://www.youtube.com/channel/UCu3M5GZI8GKDuHpi7DSmqug">AccentoDev</Link>
					</li>
				</ul>
			</div>
			<div id={style.misc}>
				<h2>Paperwork</h2>
				<ul>
					<li>
						<FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
						<Link to="/legal-notice">Legal Notice</Link>
					</li>
					<li>
						<FontAwesomeIcon icon={["fas", "user-secret"]} />{" "}
						<Link to="/privacy">
							Recording &amp; Privacy
						</Link>
					</li>
					<li style={{marginTop:"0.5em"}}>
						<Powered white={true}/>
					</li>
				</ul>
			</div>
		</div>
		<div id={style.bottomLine} />
	</footer>
)
