import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

export default ({ meta }) => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						twitter
						siteUrl
						socialImage
					}
					pathPrefix
				}
			}
		`
	)

	const site = data.site.siteMetadata
	site.url = `${site.siteUrl}${data.site.pathPrefix}`
	const fullTitleSiteLast = meta.fullTitle ?? `${meta.title} — ${site.title}`
	const fullTitleSiteFirst = meta.fullTitle ?? `${site.title} — ${meta.title}`

	return (
		<Helmet>
			<title>{fullTitleSiteLast}</title>
			<meta charset="UTF-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />

			{/* yes, it's useless for search engines, but it's documentation what we're aiming for and it helps other tools judge the SEO quality*/}
			{meta.keywords && <meta name="keywords" content={meta.keywords} />}
			{/* Google */}
			<meta name="description" content={meta.description} />
			{/* Twitter */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={site.twitter} />
			<meta name="twitter:creator" content={site.twitter} />
			<meta name="twitter:title" content={fullTitleSiteFirst} />
			<meta name="twitter:description" content={meta.description} />
			{/* JSX expressions must have a single parent, but it looks like Helmet doesn't work with `React.Fragment`
				and so we can't combine the Twitter and OpenGraph code into a single condition */}
			{meta.socialImage ? (
				<meta name="twitter:image" content={`${site.url}/${meta.socialImage}`} />
			) : (
				<meta name="twitter:image" content={`${site.url}/${site.socialImage}`} />
			)}
			{/* Open Graph */}
			<meta property="og:title" content={fullTitleSiteFirst} />
			<meta property="og:site_Name" content={`${site.title} — ${site.description}`} />
			<meta property="og:description" content={meta.description} />
			<meta property="og:url" content={`${site.url}${meta.path}`} />
			{meta.socialImage ? (
				<meta property="og:image" content={`${site.url}/${meta.socialImage}`} />
			) : (
				<meta property="og:image" content={`${site.url}/${site.socialImage}`} />
			)}
		</Helmet>
	)
}
