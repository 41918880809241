import React from "react"
import RehypeReact from "rehype-react"

import Image from "./image"
import Link from "./link"

const RenderHtml = ({ withAst, htmlAst }) => {
	const renderAst = new RehypeReact({
		createElement: React.createElement,
		components: {
			a: renderLink,
			p: renderImageInParagraph,
			img: renderImage,
		},
	}).Compiler

	return renderAst(withAst ? withAst.htmlAst : htmlAst)
}

const renderLink = ({ href, children }) => {
	return <Link to={href} children={children} />
}

// Parsed markdown has a paragraph around the image, so just rendering the image with <Image>
// leads to a warning (in the browser console) because <Image> creates <div>s and <p>s shouldn't
// contain <div>s. Thus check each paragraph and replace the entire <p> with an image.
const renderImageInParagraph = props => {
	const children = props.children
	if (props.children.length === 1) {
		const child = children[0]
		if (child.type && child.type.name === "renderImage") return renderImage(child.props)
	}
	return <p {...props}>{children}</p>
}

const renderImage = ({ src, title }) => {
	const slug = src.substring(src.lastIndexOf("/") + 1, src.lastIndexOf("."))

	const style = title ? JSON.parse(title.replace(/'/g, `"`)) : { }
	return <Image slug={slug} {...style} />
}

export default RenderHtml
