import React from "react"

import SiteMenu from "./siteMenu"
import Logo from "./logo.js"
import Powered from "./powered.js"

import style from "./header.module.css"

const Header = () => (
	<React.Fragment>
		<header>
			<div className={style.content}>
				<SiteMenu />
				<Logo />
				<div className={style.coordinates}>
					<p className={style.location}>Online</p>
					<p className={style.date}>28. Sep 2021</p>
				</div>
				<div className={style.topic}>
					<p>Back to <span id={style.topicBreak}><br/></span>the roots</p>
				</div>
			</div>
		</header>
		<div className={style.lower}>
			<div className={style.content}>
				<div className={style.powered}>
					<Powered />
				</div>
			</div>
			<div id={style.line1} className={style.lines} />
			<div id={style.line2} className={style.lines} />
		</div>
	</React.Fragment>
)

export default Header
